import React from "react"
import { graphql } from "gatsby"
import Bayout from "../../../components/blogLayout"
import Logo from "../../../components/logo"


export default ({ data }) => {
  return (
    <Bayout>
      <Logo color="black" tag="h1" productName="Release Notes" className="text-center text-md-left mb-5" fsMax={20}/>
      <div className="d-flex flex-wrap align-items-stretch w-100">
	      {
          data.allWordpressWpCustomRelease.edges.map(({ node }) => {

            return (
    	        <div className="p-5 col-12 panelShadow " key={node.id}>
    	          <h3 dangerouslySetInnerHTML={{ __html: node.title }} />
    	          <div className="text-muted d-block mb-4">{node.date}</div>
    	          <div dangerouslySetInnerHTML={{ __html: node.content }} />
    	        </div>
    	      )
          })
        }
      </div>
    </Bayout>
  )
}

export const pageQuery = graphql`
  query {
    allWordpressWpCustomRelease(sort: {fields: [date], order: [DESC]}, filter: {release_cat: {eq: 1715}}) {
    edges {
      node {
        title
        id
        date(formatString: "MMMM DD, YYYY")
        content
        release_cat
      }
    }
  }
}
`